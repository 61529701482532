import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import authService from "./Auth/AuthService";
import { Link } from "react-router-dom";


export class ReviewRegistration_Step4 extends Component {
    static displayName = ReviewRegistration_Step4.name;

   
  constructor(props) {
    super(props);
      this.state = {
          isAuthenticated: false,
          userName: null,
          firstname: "",
          lastname: "",
          middlename: "",
          regdata: [],
          totamount: null,
          forecasts: [],
          loading: true,
          itemtoremove:null
      };
      this.LoadData = this.LoadData.bind(this);
      
  }

    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
       // this.populateRegistrationData();
        //const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        //const token = await authService.getAccessToken();
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const token = await authService.getAccessToken();
        await this.setState({
            isAuthenticated:isAuthenticated,
            userName: user 
        });

        //Redirect to profile if registration complete
        fetch('api/registration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(rsp => rsp.json())
            .then(data => {
                this.setState({
                    firstname: data[0].firstName,
                    middlename: data[0].middleName,
                    lastname: data[0].lastName
                   
                });

                if (data[0].registrationStatusCode === 5) {
                    this.props.history.push('/profile');

                }
            });

        //fetch my registration data
        fetch('api/packageregistration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ regdata: data, loading: false });
            });

        //fetch my registration data
        fetch('api/packageregistration/amount/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ totamount: data });
            });

    }

    //refresh data
    async LoadData(){
        const token = await authService.getAccessToken();
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        //const token = await authService.getAccessToken();
        //fetch my registration data
        fetch('api/packageregistration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ regdata: data, loading: false });
            });

        //fetch my registration data
        fetch('api/packageregistration/amount/' + this.state.userName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ totamount: data });
            });
    }

    //Remove item
   async onRemove(e,item){
        e.preventDefault();
        this.setState({
            itemtoremove: item
        });
        const token = await authService.getAccessToken();
        //fetch delete item
        fetch('api/packageregistration', {
            method: "DELETE",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'content-type': 'application/json' },
            body: JSON.stringify(item)
        }).then(console.log(item + " removed")).then(this.LoadData);
            //.then(this.populateState);

    }

    addmore = (e) => { e.preventDefault(); /*this.props.history.push('/register/step3');*/ }


  renderReviewTable =(regdata) =>{
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>Convention Package</th>
                    <th>Amount $</th>
                    <th></th>
          </tr>
        </thead>
            <tbody>
                {regdata.map(item =>
                    <tr key={item.recId}>
                        <td></td>
                        <td>{item.firstName}</td>
                        <td>{item.middleName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.packageDescription}</td>
                        <td>{item.amount}</td>
                        <td>{item.isPrimary ? <a href='/register/step2' > modify</a>:<a href='#' onClick={(e)=>this.onRemove(e,item.recId)} > remove </a>}</td>
                    </tr>

          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderReviewTable(this.state.regdata);

    return (
        <div>
            <div className="checkout">
                <h1 id="tabelLabel" >Review My Registration</h1>
                {/*
                <p>Review this list before you make payment. Total Amt : ${this.state.totamount}  &nbsp;<a href='/register/step3'  > Go Back </a></p>*/}
                <p>Review this list before you make payment. Total Amt : ${this.state.totamount}  &nbsp;<a href='/register/step3'  > Go Back </a></p>

                <h5><a href='/register/step4'  > Please Click Here To Refresh and See all Changes </a></h5>
                
                <p>Account Holder: {this.state.userName},  {this.state.firstname} {this.state.lastname}</p> &nbsp;<a href='/register/step3' > Add Additional Package(s) / Dependant(s)</a>
                
            {contents}

            <div class="col-md-12">
                    {/*<button class="btn btn-default theme-btn" type="submit" onClick={this.onSubmit}>Proceed To CheckOut <i class="arrow_right"></i></button>*/}
                <Link to ="/pay">Make Payment</Link>
                </div>
            </div>
      </div>
    );
    }
    //Proceed to Check Out
    onSubmit = (e) => {
        e.preventDefault();
        //alert('Service Tag is: ' + this.state.servicetag);
        //const self = this;
        this.props.history.push('/register/step5');
    }
  //async populateRegistrationData(){
  //  const token = await authService.getAccessToken();
  //  const response = await fetch('weatherforecast', {
  //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  //  });
  //    const data = await response.json();
  //    this.setState({ forecasts: data, loading: false });
  //}
}
