import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import userService from '../components/Auth/UserService';

export { Address };
function Address() {
    const token = userService.getAccessToken();
   
    const [assoc, setAssocOptions] = useState([]);
    const [user, setUser] = useState();
    const [org, setOrg] = useState();
    const navigate = useNavigate();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        address: Yup.string()
            .required('Address is required'),
        city: Yup.string()
            .required('City is required'),
        state: Yup.string()
            .required('State is required').min(2, 'must be at least characters'),
        zip: Yup.string()
            .required('Zip is required')
            .min(5, 'must be 5 characters'),
        country: Yup.string()
            .required('Country is required'),
        phoneNumber: Yup.string()
            .required('Phone Number is requires'),
        association: Yup.string()
            /*.required('please select your affiliate association')*/
            
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit,reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    //load user data into form for editing
    useEffect(() => {
       /* setUser(localStorage.getItem('username'));*/
        let info = {
            email: localStorage.getItem('username')

        }
        //let res = [];
        //fetch my registration data
        fetch('api/registration/user/' + localStorage.getItem('username'), {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setOrg(data[0].affiliateOrganisationCode);
                setUser(data[0]);
                localStorage.setItem('OrgName', data[0].affiliateOrganisation);
                localStorage.setItem('OrgId',data[0].affiliateOrganisationCode);
            });
        // effect runs on component mount

    }, []);


    //Load Ceana Affiliate Associations
    useEffect(() => {
        //userService.getAll();
        //userService.getAllAttendees();

        //fetch my registration data
        fetch('api/associations/all', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAssocOptions(data);
            });
    }, []);

   
   

    //reset form
    useEffect(() => {
        console.log(user);
        reset(user);
         
    }, [user]);

    function onSubmit({ address, city, state, zip, country, phoneNumber,association }) {
        /* e.preventDefault();*/


        let addrinfo = {
            address: address,
            city: city,
            state: state,
            zip: zip,
            country: country,
            phoneNumber: phoneNumber,
            email: localStorage.getItem('username')
            //affiliateOrganisationCode: association !== parseInt(localStorage.getItem('OrgId')) ? association : localStorage.getItem('OrgId'),
            //affiliateOrganisation: association !== parseInt(localStorage.getItem('OrgId')) ? assoc.filter(x => x.recId == association)[0].name : localStorage.getItem('OrgName'),/*assoc.filter(x => x.recId == association)[0].name,*/

            //affiliateOrganisationCode: association !== parseInt(localStorage.getItem('OrgId')) ? association : localStorage.getItem('OrgId'),
            //affiliateOrganisation: association !== parseInt(localStorage.getItem('OrgId')) ? assoc.filter(x => x.recId == association)[0].name : localStorage.getItem('OrgName') ,/*assoc.filter(x => x.recId == association)[0].name,*/
           
        };

        console.log(addrinfo);
        fetch("api/registration/updateaddress", {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(addrinfo)
        }).then((rsp) => {
            if (rsp.status === 201 || rsp.status === 200) {
                console.log('Address Updated successfully.')
                navigate('/myattendees');
            }
            else {
                alert('Sorry Account Could not be Created, try again');
            }

        }).catch((err) => {
            toast.error('Failed :' + err.message);
            console.log('Address Updated successfully.');
        });

    }

    //fires on Packages Options Change
  function onPackageOptionChange(event) {
        event.preventDefault();
      //console.log(event.target.value + "status code selected");
      //localStorage.setItem('orgId', event.target.value);
        //let sObj = this.state.packages.filter(opt => opt.packageId === parseInt(event.target.value));
        //this.setState({
        //    packageid: sObj[0].packageId,
        //    packagedescription: sObj[0].description,
        //    packageoption: sObj[0].packageId,
        //    amount: sObj[0].amount
        //});
    }

    //Association options data
    let assocOptionData = assoc;
    let optionItems = assocOptionData.map(item =>
        <option key={item.recId} value={parseInt(item.recId)}>
            {item.name}
        </option>
    );

   /* <option key={item.recId} value={parseInt(item.recId)} name={item.name} selected={user.affiliateOrganisationCode === null ? '' : user.affiliateOrganisationCode}> {item.name} </option>*/


    return (
        <div className="container">
           {/* <h4 className="">Contact Information</h4>*/}
            <div className="default-col ticket-col checkout">
                <div className="row">
                    <div class="col-lg-12">
                        <div class="title-col title-white-col assoc-bg">
                            <h6>My Current Affiliated Association: </h6>
                            <span className="assoc"> {localStorage.getItem('OrgName')}</span>
                            <Link to="/myprofile/assocedit" on className="btn btn-link">modify</Link>
                            <div class="title-bdr"></div>
                        </div>
                    </div>
                </div>
             
                <div class="row">
                    <div class="col-lg-12">
                        <div class="title-col title-white-col">

                            <h3>Mailing Address</h3>
                            <p style={{ color: '#a3520f' }}>All Correspondence will be sent to this address:</p>
                            <div class="title-bdr"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="row">
                            <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-4">
                        <label className="form-label">Phone Number</label>
                        <input name="phoneNumber" type="text" {...register('phoneNumber')} className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-7">
                                    <label className="form-label">Address</label>
                                    <input name="address" type="text" {...register('address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.address?.message}</div>
                                </div>
                                <div className="col-md-5">
                                    <label className="form-label">City</label>
                                    <input name="city" type="text" {...register('city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.city?.message}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                 <label className="form-label">State</label>
                                 <input name="state" type="text" {...register('state')} className={`form-control ${errors.state ? 'is-invalid' : ''}`} />
                                 <div className="invalid-feedback">{errors.state?.message}</div>
                                </div>
                                <div className="col-md-4">
                                <label className="form-label">Zip /Postal Code</label>
                                <input name="zip" type="zip" {...register('zip')} className={`form-control ${errors.zip ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.zip?.message}</div>
                                </div>
                                <div className="col-md-4">
                                                        <label className="form-label">Country</label>
                                                        <input name="country" type="country" {...register('country')} className={`form-control ${errors.country ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.country?.message}</div>
                                  </div>
                            </div>
                            {/*{parseInt(localStorage.getItem('OrgId')) === 0 &&*/}

                           
                            {/*<div className="row">*/}
                               
                            {/*    <div className="col-md-8" style={{ marginTop: "20px" }}>*/}
                                    
                            {/*        <label className="form-label">Affiliate Association {localStorage.getItem('OrgName') }</label>*/}
                            {/*        <select name="title" {...register('association')}   className=" form-select" onChange={onPackageOptionChange} >*/}
                            {/*            {optionItems}*/}
                            {/*        </select>*/}
                            {/*        <div className="invalid-feedback">{errors.association?.message}</div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                            {/*}*/}

                        
                        
                            <button disabled={isSubmitting} className="btn btn-primary theme-btn addrbtn" style={{ marginTop: '20px', marginBottom:'20px' } }>
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        Save & Continue
                    </button>
                    {/*<Link to="/myprofile" on className="btn btn-link">Cancel</Link>*/}
                </form>
                </div>

                </div>
               
            </div>
        </div>
    )
}
