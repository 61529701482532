
import React, { Component }  from 'react';
import authService from "./Auth/AuthService";

export class SelectPackage_Step2 extends Component {
    static displayName = SelectPackage_Step2.name;
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            email: null,
            arrivaldate: '2022-09-01',
            departuredate: '2022-09-04',
            packageid: 1,
            packagedescription: 'Full Convention Package Ages 18+',
            amount: 190.00,
            packageoption:1,
            packages:[]
        }
        //fetch conference package options from db
        fetch('api/packages/all')
            .then(response => response.json())
            .then(data => {
                this.setState({ packages: data });
            });

    }
    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
        //this.populateRegistrationData();
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const token = await authService.getAccessToken();
        this.setState({
            isAuthenticated,
            userName: user,
            email: user 
        });

        //fetch my registration profile info
        fetch('api/registration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            //.then(rsp => { if (rsp.status === 201 || rsp.status === 200) { rsp.json() } })
            .then(rsp => rsp.json())
            .then(data => {
                //if (data != null) {
                    this.setState({
                        firstname: data[0].firstName,
                        middlename: data[0].middleName,
                        lastname: data[0].lastName,
                        phonenumber: data[0].phoneNumber,
                        address: data[0].address,
                        city: data[0].city,
                        state: data[0].state,
                        zip: data[0].zip,
                        country: data[0].country,
                        registrationstatuscode: data[0].registrationStatusCode,
                        registrationstatus: data[0].registrationStatus,
                        arrivaldate: data[0].registrationStatusCode === 1 ? '2022-09-01' : data[0].arrivalDate.substring(0, 10) ,
                        departuredate: data[0].registrationStatusCode === 1 ? '2022-09-04' : data[0].departureDate.substring(0, 10)
                    });
                     if (data[0].registrationStatusCode === 5) {
                        this.props.history.push('/profile');
    
                    }
                //}
               
            });
        //fetch package registration info
        fetch('api/packageregistration/userprimary/' + this.state.userName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(rsp => { if (rsp.status === 201 || rsp.status === 200) { rsp.json() } })
            .then(data => {
                if (data != null) {
                    this.setState({
                        // firstname: data[0].firstName,
                        // middlename: data[0].middleName,
                        packageid: data[0].packageId,
                        packagedescription: data[0].packageDescription,
                        amount: data[0].amount,
                        packageoption: data[0].packageId
                    });
                }
            }
                 );
    }

    //fires on Packages Options Change
    onPackageOptionChange = (event) => {
        event.preventDefault();
        console.log(event.target.value + "status code selected");
        let sObj = this.state.packages.filter(opt => opt.packageId === parseInt(event.target.value));
        this.setState({
            packageid: sObj[0].packageId,
            packagedescription: sObj[0].description,
            packageoption: sObj[0].packageId,
            amount: sObj[0].amount
        });
    }

    //fires on Input Box Value Change
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //
    onSubmit = (e) => {

        e.preventDefault();
        //Post to Database
        //alert('Service Tag is: ' + this.state.servicetag);
        //const self = this;

        let info = {
            email: this.state.email,
            arrivaldate: this.state.arrivaldate,
            departuredate: this.state.departuredate,
            packageid: this.state.packageid,
            description: this.state.packagedescription,
            amount: this.state.amount
        };


        var url = '/api/registration/save2';


        fetch(url, {
            body: JSON.stringify(info),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            credentials: 'same-origin',
            method: 'POST'
        })
            .then(rsp => {
                if (rsp.status === 201 || rsp.status === 200) {
                    console.log('Info Added Successfully ' + this.state.scans + 'Please Check Email');
                    // alert('Info Added Successfully ' + this.state.scans + 'Please Check Email');
                    this.props.history.push('/register/step3');
                }
            })
            .catch(err => {
                console.error(err);
                alert(err);
            });

        this.setState({ scans: [], showform: false });

    }

    render() {

        //package options data
        let packageoptiondata = this.state.packages;
        let optionItems = packageoptiondata.map(item =>
            <option key={item.packageId} value={parseInt(item.packageId)} name={item.description}>{item.description}{' $' + item.amount}</option>
        );

        return (
            <div>
              {/*  <!-- Ticket Start -->*/}
 
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="title-col title-white-col">
                                <h6>Step 2 of 5  &nbsp;<a href='/register/step1' > Go Back</a></h6>
                                    <h2>Select Your <span> Registration Package Only</span></h2>
                                    <div class="title-bdr"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                            <div class="default-col ticket-col checkout">
                                <p style={{ color: '#a3520f' }}>Choose Your Registration Package Only from the List Below </p>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12">
                                            <select id="packageoptions" class="dropdownlist" onChange={this.onPackageOptionChange} value={this.state.packageoption} name="packageoption">
                                                {optionItems}
                                                {/*
                                                <option>Full Package - 18 + & Adult - $170</option>
                                                <option>Full Package - Children 10 to 17yrs  - $100</option>
                                                <option>Banquet Only Adult - $100</option>
                                                <option>Banquet Only Children 0 to 9yrs - $60</option>
                                                <option>Banquet Only Children 10 to 17yrs - $60</option>
                                                <option>Pic Nic Only Adult - $20</option>
                                                */}
                                            </select>
                                            </div>
                                        <div class="col-md-6">
                                            <label> Arrival Date</label>
                                            <input type="text" class="form-control" placeholder="Arrival Date yyyy/mm/dd" name="arrivaldate" type="date" value={this.state.arrivaldate} onChange={this.handleChange} />
                                         </div>
                                        <div class="col-md-6">
                                            <label> Departure Date</label>
                                            <input type="text" class="form-control" placeholder="Departure Date yyyy/mm/dd" name="departuredate" type="date" value={this.state.departuredate} onChange={this.handleChange} />
                                        </div>
                                        
                                                       
                                                           
                                                        </div>
                                    </form>
                                    <div class="col-md-12">
                                    <button class="btn btn-default theme-btn" type="submit" onClick={this.onSubmit}>Save & Continue <i class="arrow_right"></i></button>
                                    </div>
                                </div>
                             </div>
                        </div>
        </div>
                                  
       
   

            </div>
        );
    }
}