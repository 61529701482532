
import { NavLink,useNavigate } from 'react-router-dom';
//import { useSelector, useDispatch } from 'react-redux';

//import { authActions } from '_store';
import authService from "./Auth/AuthService";
import userService from './Auth/UserService';

export { Nav };

function Nav() {
    //const auth = authService.isAuthenticated();//useSelector(x => x.auth.value);
    const auth = JSON.parse(localStorage.getItem('uAuth')) === true ? true : false //userService.isAuthenticated();
    //const dispatch = useDispatch();
    const navigate = useNavigate();
    const login = () => navigate('/login');//authService.login();
    const logout = () => authService.signOut();
    // only show nav when logged in
   // if (!auth) return null;

    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark px-3 navMenu justify-content-center">
            <div className="navbar-nav">
                <NavLink to="/" className="nav-item nav-link">Ceana Events</NavLink>
                {auth && 
                    <NavLink to="/myprofile" className="nav-item nav-link">My Profile</NavLink>}
                {!auth&&<button onClick={login} className="btn btn-link nav-item nav-link" >Login</button>}
               
                {auth &&
                    <button onClick={logout} className="btn btn-link nav-item nav-link" >Logout</button> }
               

                <NavLink to="/about" className="nav-item nav-link">About</NavLink>
                {/*<button onClick={login} className="btn btn-link nav-item nav-link" hidden={authService.isAuthenticated() == true ? '' : 'hidden'}>Login</button>*/}

                {/*<button onClick={logout} className="btn btn-link nav-item nav-link" hidden={auth == false ? '' : 'hidden'}>Logout</button>*/}
            </div>
        </nav>
    );
}