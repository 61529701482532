
import { useEffect, useState } from 'react';

function SendMail(props) {


    useEffect(() => {

    }, []);

    const SendMail = (event) => {
        /* event.preventDefault();*/

        let email = localStorage.getItem("username");
        let addrinfo = {
            email: "info@ndigo.io"
           
        };

        console.log(email);
        fetch("api/users/sendmail", {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(email)
        }).then((rsp) => {
            if (rsp.status === 201 || rsp.status === 200) {
                // console.log('password reset successfully.')
                // navigate('/resetsuccess');
                //setPassword(_status = !_status);
                //handleClick();
                console.log("Email Sent")
            }
            else {
                alert('Sorry email could not be sent');
                console.log("Could not send email");
            }

        }).catch((err) => {
            //toast.error('Failed :' + err.message);
            console.log('password reset unsuccessfully.');
        });

    }

    return (
        <div>
            {/*  <!-- Ticket Start -->*/}

            <div className="container">
                <div className="row">
                    <div class="col-lg-12">

                        <h1 style={{ fontSize: "50px" }}>Send Mail !! </h1>
                        <div class="title-bdr"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    

                        <div className="row">
                            <div className="col-md-5">
                                <label className="form-label">Email</label>
                                <input name="email" type="text"   />
                                <div className="invalid-feedback"></div>
                            </div>


                        </div>
                   

                        <button onClick={SendMail()}  className="btn btn-primary theme-btn addrbtn" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            
                            Send Mail
                        </button>
                        {/*<Link to="/myprofile" on className="btn btn-link">Cancel</Link>*/}
                    
                </div>
            </div>





        </div>
    );
}

export default SendMail;