
import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
//import { withRouter } from 'react-router-dom';
//import { Label } from 'react-bootstrap';
import authService from "./Auth/AuthService";

import  { AppNavigator } from './AppNavigator';

export class AddDependantForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            email:null,
            myref: React.createRef(),
            firstname: "",
            lastname: "",
            middlename: "",
            packageoption: '',
            description: '',
            packageid: 1,
            packagedescription: 'Full Convention Package Ages 18+',
            amount: 190.00,
            packageoption: 1,
            packages: [],
            scans: []
        }
       
    }
    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
        //this.populateRegistrationData();
        //fetch conference package options from db
        fetch('api/packages/all')
            .then(response => response.json())
            .then(data => {
                this.setState({ packages: data });
            });
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const token = await authService.getAccessToken();
        this.setState({
            isAuthenticated:isAuthenticated,
            userName: user ,
            email: user 
        });
        ////Redirect to profile if registration complete
        //fetch('api/registration/user/' + this.state.userName, {
        //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //}).then(rsp => rsp.json())
        //    .then(data => {
        //        if (data[0].registrationStatusCode === 5) {
        //            this.props.history.push("/profile");
        //        }
        //    }).catch(err => {
        //        console.log(err);
        //    });

       
    }

    //
    //fires on Packages Options Change
    onPackageOptionChange = (event) => {
        event.preventDefault();
        console.log(event.target.value + "status code selected");
        let sObj = this.state.packages.filter(opt => opt.packageId === parseInt(event.target.value));
        this.setState({
            packageid: sObj[0].packageId,
            packagedescription: sObj[0].description,
            packageoption: sObj[0].packageId,
            amount: sObj[0].amount
        });
    }

    //fires on Input Box Value Change
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //
    onAddDependant = (e) => {

        e.preventDefault();
        //Post to Database
        //alert('Service Tag is: ' + this.state.servicetag);
        //const self = this;

        let dependantInfo = {
            email: this.state.email,
            myref: this.state.myref,
            firstname: this.state.firstname,
            middlename: this.state.middlename,
            lastname: this.state.lastname,
            packageid: this.state.packageid,
            description: this.state.packagedescription,
            amount: this.state.amount
        };
        //pass data on to parent
        this.props.onSubmit(dependantInfo);
        console.log(dependantInfo);
    }


    render() {
        //package options data
        let packageoptiondata = this.state.packages;
        let optionItems = packageoptiondata.map(item =>
            <option key={item.packageId} value={parseInt(item.packageId)} name={item.description}>{item.description}{' $' +item.amount}</option>
        );

    return (
        <div class="row">
            <div class="col-lg-8">
                <div class="default-col ticket-col">
                    <p style={{ color: '#a3520f' }}>Add your relatives you want to register and all your tour packages (Tour packages for yourself and relatives)</p>

                    <form onSubmit={this.onAddDependant}>
                        <div class="row">

                            <div class="col-md-4">
                                <input type="text" class="form-control" placeholder="First Name" name="firstname" value={this.state.firstname} onChange={this.handleChange} />
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control" placeholder="Middle Name" name="middlename" value={this.state.middlename} onChange={this.handleChange} />
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={this.handleChange} />
                            </div>


                            <div class="col-md-12">
                                <select id="packageoptions" class="dropdownlist" onChange={this.onPackageOptionChange} value={this.state.packageoption} name="packageoption">
                                    {optionItems}

                                </select>
                            </div>

                        </div>
                   
                    <div class="col-md-12">
                        <button class="btn btn-default theme-btn" type="submit" >Add Extra Package & Dependant <i class="arrow_right"></i></button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        );
}
}

//export default withRouter(AddDependantForm);
//Dependant Scan Template

export class Dependant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myref: this.props.myref
        }
    }
    handleRemove = (e) => {
        e.preventDefault();
        this.props.onRemoveItem(this.props.id);
    }
    render() {
        return (
            <div style={{ margin: '1em' }}>
                <img width="30" src={this.props.avatar_url} alt="" />
                <div style={{ display: 'inline-block', marginLeft: 2 }}>
                    <div style={{ fontsize: '1.25em', fontWeight: 'bold' }}>

                        <a href="#" onClick={this.handleRemove} style={{ color: '' }}>remove</a>
                        {/*<Label bsStyle="danger"><a href="#" onClick={this.handleRemove} style={{ color: '#ffffff' }}> remove</a></Label>
                        #: {this.props.i}*/}

                    </div>
                    <div>{this.props.firstname} &nbsp;{this.props.middlename} &nbsp;{this.props.lastname} &nbsp; {this.props.description} &nbsp;&nbsp; {' $'+this.props.amount}  </div>
                </div>
            </div>

        );
    }
}

//Dependants scanned list
export const DependantList = (props) => {

    //const onRemoveItem = (i) => {
    //    let modified = props.scans.filter(x => x.key !== i);
    //    props.scans.concat(modified);
    //}
    return (
        <div>
            {
                //props.scans.map((scan, i) => <Dependant key={i} id={i} {...scan} onRemoveItem={props.onRemoveItem} />)
                props.scans.map((scan, i) => <Dependant key={i} id={i} {...scan} onRemoveItem={props.onRemoveItem} />)
            }
        </div>
    );
}

export class AddDependant_Step3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            scans: [

            ],
            keynum: null,
            registrationComplete: false,
            packagedata: '',
            primaryfname: '',
            primarylname:''

        }
    }

    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
        //this.populateRegistrationData();
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const token = await authService.getAccessToken();
        this.setState({
            isAuthenticated:isAuthenticated,
            userName: user ,
            email: user 
           
        });
        //fetch my registration profile info
        fetch('api/registration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            //.then(rsp => { if (rsp.status === 201 || rsp.status === 200) { rsp.json(); } })
            .then(rsp => rsp.json())
            .then(data => {
                /*  if (!(data === undefined || data.length == 0)) {*/
              if (data[0].registrationStatusCode === 5) {
                    this.props.history.push('/profile');

                }
            })
            .catch(err => {
                console.log(err);
            });

        //fetch my package registration data
        fetch('api/packageregistration/user/' + this.state.userName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(rdata => {
                this.setState({
                    packagedata: rdata[0].packageDescription,
                    primaryfname: rdata[0].firstName,
                    primarylname: rdata[0].lastName,
                    loading: false
                });
            });
    }

    addNewScan = (scanInfo) => {
            this.setState(prevState => ({
                scans: prevState.scans.concat(scanInfo)
            })) 
    };

    ////remove dependent item
    onRemoveItem = (i) => {
        this.setState(prevState => ({
            scans: prevState.scans.filter((item, index) => i !== index)
        }));
    }

    skip = (e) => { this.props.history.push('/register/step4');}
    
    //Remove item from Scan
    //deleteScanItem = (i) => {
    //    this.setState(prevState => ({
    //        scans: prevState.scans.filter(x => x.myref !== i.myref)
    //    }));
    //}

    //Post to Database
    onSubmit = (e) => {
        e.preventDefault();
        //alert('Service Tag is: ' + this.state.servicetag);
        //const self = this;

        var url = '/api/registration/save3';
        var i;
        for (i = 0; i < this.state.scans.length; i++) {
            fetch(url, {
                body: JSON.stringify(this.state.scans[i]),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json'
                },
                credentials: 'same-origin',
                method: 'POST'
            })
                .then(rsp => {
                    if (rsp.status === 201 || rsp.status === 200) {
                        console.log('Device with Service Tag ' + this.state.scans + 'added successfully');
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
        this.setState({ scans: [] });
        //this.props.history.push('/register/step4');

        //AppNavigator('/register/step4');
        //appNavigator('/register/step4');
    };


    render() {
        return (
            <div>
              {/*  <!-- Ticket Start -->*/}
 
                    <div class="container checkout">
                        <div class="row">
                            <div class="col-lg-12">
                            <div class="title-col title-white-col">
                                {/*//<h6>Step 3 of 5</h6> <a href='#' onClick={this.skip} > Skip this step</a>*/}
                                <h6>Step 3 of 5  &nbsp;<a href='/register/step2' > Go Back</a>  &nbsp;&nbsp;<a href='/register/step4' > Skip This Step</a></h6> 
                                {/*
                                <p>Account Holder: {this.state.regdata[0].packageDescription}</p>*/}
                                <h6>You Have Selected Package:</h6>
                                <h4>{this.state.packagedata} ({this.state.primaryfname} {this.state.primarylname})</h4>
                                <br/>
                                
                                <h2>Add Your Dependants/Relatives and Tour Packages</h2><p><b>{this.state.scans.length}</b> Dependant(s)/Tour Package(s) added</p>
                                    <div class="title-bdr"></div>
                                </div>
                            </div>
                    </div>
                    <AddDependantForm onSubmit={this.addNewScan} />
                    
                  
                    <DependantList scans={this.state.scans} onRemoveItem={this.onRemoveItem} /> <br /> <br />
                    <div class="col-md-12">
                        <button class="btn btn-default theme-btn" type="submit" onClick={this.onSubmit}>Save <i class="arrow_right"></i></button>
                    </div>   
        </div>
                                  
       
   

            </div>
        );
    }
}