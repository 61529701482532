import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import authService from "./Auth/AuthService";
import { Link, useNavigate } from "react-router-dom";
/*import { Address } from './Address';*/
import { Arrival } from './Arrival';


export class Review extends Component {
    static displayName = Review.name;
    
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            firstname: "",
            lastname: "",
            middlename: "",
            regdata: [],
            totamount: null,
            forecasts: [],
            loading: true,
            itemtoremove: null
        };
        this.LoadData = this.LoadData.bind(this);

    }

    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
        // this.populateRegistrationData();
        //const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        //const token = await authService.getAccessToken();
    }

     populateState() {
        //const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        //const token = await authService.getAccessToken();
        const token = localStorage.getItem('jwttoken');
        const user = localStorage.getItem('username');
        const isAuthenticated = JSON.parse(localStorage.getItem('uAuth'));

        this.setState({
            isAuthenticated: isAuthenticated,
            userName: user
        });

        //Redirect to profile if registration complete
        fetch('api/registration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(rsp => rsp.json())
            .then(data => {
                this.setState({
                    firstname: data[0].firstName,
                    middlename: data[0].middleName,
                    lastname: data[0].lastName

                });

                if (data[0].registrationStatusCode === 5) {
                    this.props.history.push('/profile');

                }
            });

        //fetch my registration data
        fetch('api/packageregistration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ regdata: data, loading: false });
            });

        //fetch my registration data
        fetch('api/packageregistration/amount/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ totamount: data });
            });

    }

    //refresh data
    LoadData() {
        //const token = await authService.getAccessToken();
        //const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        //const token = await authService.getAccessToken();
        //fetch my registration data
        const token = localStorage.getItem('jwttoken');
        const user = localStorage.getItem('username');
        const isAuthenticated = JSON.parse(localStorage.getItem('uAuth'));

        fetch('api/packageregistration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ regdata: data, loading: false });
            });

        //fetch my registration data
        fetch('api/packageregistration/amount/' + this.state.userName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ totamount: data });
            });
    }

    //Remove item
    onRemove(e, item) {
        e.preventDefault();
        this.setState({
            itemtoremove: item
        });
        const token = authService.getAccessToken();

        //fetch delete item
        fetch('api/packageregistration', {
            method: "DELETE",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'content-type': 'application/json' },
            body: JSON.stringify(item)
        }).then(console.log(item + " removed")).then(this.LoadData);
        //.then(this.populateState);

    }

    addmore = (e) => { e.preventDefault(); /*this.props.history.push('/register/step3');*/ }

    //CreatePaymentIntent and Redirect to Pay
    handlePaymentIntent(e) {
        e.preventDefault();
        const token = authService.getAccessToken();
        //fetch delete item
        let item = {
            amount: this.state.totamount,

        }
        fetch('api/secret', {
            method: "POST",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'content-type': 'application/json' },
            body: JSON.stringify(item)
        }).then(console.log(item + " removed")).then(this.LoadData);
    }


    renderReviewTable = (regdata) => {
       

        return (

            <table className='table table-striped attendees' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        <th>Convention Package</th>
                        <th>Amount $</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {regdata.map(item =>
                        <tr key={item.recId}>
                            <td></td>
                            <td>{item.firstName}</td>
                            <td>{item.middleName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.packageDescription}</td>
                            <td>{item.amount}</td>
                            <td>{item.isPrimary ? <a href={'/myattendees/edit/'+ item.recId} > modify</a> : <a href='#' onClick={(e) => this.onRemove(e, item.recId)} > remove </a>}</td>
                        </tr>

                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderReviewTable(this.state.regdata);

        return (
            <div>
                <div className="container">
                    <div>
                        <div className="row">
                            <div class="">
                                <div class="title-col title-white-col">
                                    <h6>Ceana 2024 Convention Registration:{/*&nbsp;<a href='/dependants' > My Attendees</a>*/}</h6>
                                    <h3>Review Your Convention Registration</h3>
                                    <p style={{ color: '#a3520f', fontWeight:'bold' }}>Account Holder: {this.state.userName},  {this.state.firstname} {this.state.lastname}</p> 
                                    
                                    <div class="title-bdr"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <h5 style={{color:'darkslategrey'} }>Make Sure All Convention Packages and your entourage are selected before you make payment. Total Amt Due :<b> ${this.state.totamount} </b></h5>
                        </div>

                    

                   {/* <h5><a href='/review'  > Please Click Here To Refresh and See all Changes </a></h5>*/}

                        <div className="row">
                            <div class="" style={{marginTop:"30px"} }>
                                {contents}
                            </div>
                           

                        </div>
                  
                    
                    {/*<div className="address" >*/}
                    {/*    <Arrival/>*/}
                    {/*</div>*/}
                    {/*<div className="address" >*/}
                    {/*    <Address />*/}
                    {/*</div>*/}
                   
                    <div class="" >
                            {/*<button class="btn btn-default theme-btn" type="submit" onClick={this.onSubmit}>Proceed To CheckOut <i class="arrow_right"></i></button>*/}
                            <div>
                                <button type="button" class="button addrbtn reviewbtn" onClick={() => window.location.href = "/myattendees"}>Go Back</button>
                          
                            <button type="button" class="button" onClick={() => window.location.href = "/pay"}>Finalize Payment!!</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    //Proceed to Check Out
    onSubmit = (e) => {
        e.preventDefault();
        //alert('Service Tag is: ' + this.state.servicetag);
        //const self = this;
        this.props.history.push('/pay');
    }
    
}
