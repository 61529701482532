import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import userService from '../components/Auth/UserService';

export { AssocEdit};
function AssocEdit() {
    const token = userService.getAccessToken();

    const [assoc, setAssocOptions] = useState([]);
    const [user, setUser] = useState();
    const [org, setOrg] = useState();
    const navigate = useNavigate();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        
        association: Yup.string()
        .required('please select your affiliate association')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    //load user data into form for editing
    useEffect(() => {
        /* setUser(localStorage.getItem('username'));*/
        let info = {
            email: localStorage.getItem('username')

        }
        //let res = [];
        //fetch my registration data
        fetch('api/registration/user/' + localStorage.getItem('username'), {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setOrg(data[0].affiliateOrganisationCode);
                setUser(data[0]);
                localStorage.setItem('OrgName', data[0].affiliateOrganisation);
                localStorage.setItem('OrgId', data[0].affiliateOrganisationCode);
            });
        // effect runs on component mount

    }, []);


    //Load Ceana Affiliate Associations
    useEffect(() => {
        //userService.getAll();
        //userService.getAllAttendees();

        //fetch my registration data
        fetch('api/associations/all', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAssocOptions(data);
            });
    }, []);




    //reset form
    useEffect(() => {
        console.log(user);
        reset(user);

    }, [user]);

    function onSubmit({  association }) {
        /* e.preventDefault();*/


        let addrinfo = {
            
            email: localStorage.getItem('username'),
            affiliateOrganisationCode: association ,
            affiliateOrganisation: assoc.filter(x => x.recId == association)[0].name /*assoc.filter(x => x.recId == association)[0].name,*/

            //affiliateOrganisationCode: association !== parseInt(localStorage.getItem('OrgId')) ? association : localStorage.getItem('OrgId'),
            //affiliateOrganisation: association !== parseInt(localStorage.getItem('OrgId')) ? assoc.filter(x => x.recId == association)[0].name : localStorage.getItem('OrgName') ,/*assoc.filter(x => x.recId == association)[0].name,*/

        };

        console.log(addrinfo);
        fetch("api/registration/updateassociation", {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(addrinfo)
        }).then((rsp) => {
            if (rsp.status === 201 || rsp.status === 200) {
                console.log('Association Updated successfully.')
                navigate('/myprofile');
            }
            else {
                alert('Sorry association could not be updated');
            }

        }).catch((err) => {
            toast.error('Failed :' + err.message);
            console.log('Association Updated successfully.');
        });

    }

    //fires on Packages Options Change
    function onPackageOptionChange(event) {
        event.preventDefault();
        //console.log(event.target.value + "status code selected");
        //localStorage.setItem('orgId', event.target.value);
        //let sObj = this.state.packages.filter(opt => opt.packageId === parseInt(event.target.value));
        //this.setState({
        //    packageid: sObj[0].packageId,
        //    packagedescription: sObj[0].description,
        //    packageoption: sObj[0].packageId,
        //    amount: sObj[0].amount
        //});
    }

    //Association options data
    let assocOptionData = assoc;
    let optionItems = assocOptionData.map(item =>
        <option key={item.recId} value={parseInt(item.recId)}>
            {item.name}
        </option>
    );

    /* <option key={item.recId} value={parseInt(item.recId)} name={item.name} selected={user.affiliateOrganisationCode === null ? '' : user.affiliateOrganisationCode}> {item.name} </option>*/


    return (
        <div className="container">
            {/* <h4 className="">Contact Information</h4>*/}
            <div className="default-col ticket-col checkout">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="title-col title-white-col">

                            <h4>My Current Affiliated Association:</h4>
                          
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="row">
                        <p><span className="assoc"> {localStorage.getItem('OrgName')}</span> </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                                           
                         <div className="row">

                                    <div className="col-md-8" style={{ marginTop: "20px" }}>

                                        <label className="form-label">Select New Association</label>
                                        <select name="title" {...register('association')} className=" form-select" onChange={onPackageOptionChange} >
                                            {optionItems}
                                        </select>
                                        <div className="invalid-feedback">{errors.association?.message}</div>
                                    </div>

                                </div>
                         



                            <button disabled={isSubmitting} className="btn btn-primary theme-btn addrbtn" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                Save
                            </button>
                            <Link to="/myprofile" className="btn btn-link">Cancel</Link>
                            {/*<Link to="/myprofile" on className="btn btn-link">Cancel</Link>*/}
                        </form>
                    </div>

                </div>

            </div>
        </div>
    )
}
