
import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import authService from "./Auth/AuthService";
import { AppNavigator } from './AppNavigator';
//
export class Register_Step1 extends Component {
    static displayName = Register_Step1.name

   
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            firstname: "",
            lastname: "",
            middlename:"",
            email: null,
            phonenumber: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            registrationstatuscode: null,
            registrationstatus:""
        }
    }

    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
        //this.populateRegistrationData();
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const token = await authService.getAccessToken();
        this.setState({
            isAuthenticated,
            userName: user,
            email: user 
        });
        //fetch my registration profile info
        fetch('api/registration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            //.then(rsp => { if (rsp.status === 201 || rsp.status === 200) { rsp.json(); } })
            .then(rsp => rsp.json())
            .then(data => {
                /*  if (!(data === undefined || data.length == 0)) {*/
                    this.setState({
                        firstname: data[0].firstName,
                        middlename: data[0].middleName,
                        lastname: data[0].lastName,
                        phonenumber: data[0].phoneNumber,
                        address: data[0].address,
                        city: data[0].city,
                        state: data[0].state,
                        zip: data[0].zip,
                        country: data[0].country,
                        registrationstatuscode: data[0].registrationStatusCode,
                        registrationstatus: data[0].registrationStatus
                    });

                    if (data[0].registrationStatusCode === 5) {
                    //this.props.history.push('/profile');

                     }


                  }/* */

               
        )
            .catch(err => {
                console.log(err);
            });
    }

    //fires on Input Box Value Change
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    //redirect to step2
    renderRedirect = () => {
       // return <Navigate to='/register/step2' />
        //usenavigate('/register/step2');
       // AppNavigator.nav('/register/step2');
    }
    onSubmit = (e) => {
       
        e.preventDefault();
         //Post to Database
            //alert('Service Tag is: ' + this.state.servicetag);
            //const self = this;

        let modifyInfo = {
            firstname: this.state.firstname,
            midddlename: this.state.middlename,
            lastname: this.state.lastname,
            email: this.state.email,
            phonenumber: this.state.phonenumber,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            country: this.state.country,
            registrationstatuscode: this.state.registrationstatuscode,
            registrationstatus: this.state.registrationstatus
            };


            var url = '/api/registration/save1';


            fetch(url, {
                body: JSON.stringify(modifyInfo),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json'
                },
                credentials: 'same-origin',
                method: 'POST'
            })
                .then(rsp => {
                    if (rsp.status === 201 || rsp.status === 200) {
                        console.log('Info Added Successfully ' + this.state.scans + 'Please Check Email');
                       // alert('Info Added Successfully ' + this.state.scans + 'Please Check Email');
                        this.props.history.push('/register/step2');

                    }
                })
                .catch(err => {
                    console.error(err);
                    alert(err);
                });

            this.setState({ scans: [], showform: false });
      
    }
    render() {
        return (
            <div>
              {/*  <!-- Ticket Start -->*/}
 
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="title-col title-white-col">
                                    <h6>Step 1 of 5</h6>
                                    <h2>Convention <span>Registration</span></h2>
                                    <div class="title-bdr"></div>
                                </div>
                            </div>
                        </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="default-col ticket-col checkout">
                                {/* <p>(Please confirm your ticket before August 3rd, 2022)</p>*/}
                                {/*Form begin*/}
                                <div class="row">

                                    <div class="col-md-4">
                                        <input type="text" class="form-control" placeholder="First Name" name="firstname" value={this.state.firstname} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control" placeholder="Middle Name" name="middlename" value={this.state.middlename} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={this.handleChange} />
                                    </div>



                                    <div class="col-md-6">
                                        <input type="text" class="form-control" placeholder="Phone Number" name="phonenumber" value={this.state.phonenumber} onChange={this.handleChange} />
                                    </div>

                                    {/*Form end*/}

                                </div>
                                <div class="row">
                                    <div class="col-lg-8">
                                        <div class="default-col ticket-col">
                                            <p>(All corespondence will be sent to this address)</p>
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <input type="text" class="form-control" placeholder="Street Address or P.O.Box" name="address" value={this.state.address} onChange={this.handleChange} />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="form-control" placeholder="City" name="city" value={this.state.city} onChange={this.handleChange} />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="form-control" placeholder="State" name="state" value={this.state.state} onChange={this.handleChange} />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="form-control" placeholder="Zip" name="zip" value={this.state.zip} onChange={this.handleChange} />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="Your Country" name="country" value={this.state.country} onChange={this.handleChange} />
                                                    </div>


                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn btn-default theme-btn" type="submit" onClick={this.onSubmit}>Save & Continue <i class="arrow_right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
                                  
       
   

            </div>
        );
    }
   

}