
import { Routes, Route,useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import userService from '../components/Auth/UserService';

export default PostLogin;
function PostLogin() {
    const token = userService.getAccessToken();
    const navigate = useNavigate();

    //check registration Status
    //load user data into form for editing
    useEffect(() => {
        
        let info = {
            email: localStorage.getItem('username')

        }
        
        //fetch my registration data
        fetch('api/registration/user/' + localStorage.getItem('username'), {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {

                if (data[0].registrationStatusCode === 2) {

                    localStorage.setItem('uReg', true);
                    //navigate to Registration Details
                    navigate('/RegistrationCompleteDetails');
                }
                else {
                    localStorage.setItem('uReg', false);
                    //navigate to MyProfile
                    navigate('/myprofile');

                }

                

            });
       

    }, []);
}