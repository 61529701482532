
import { useEffect, useState } from 'react';

function PwdResetRequestSuccess(props) {


    //useEffect(() => {

    //}, []);

    return (
        <div>
            {/*  <!-- Ticket Start -->*/}

            <div className="container">
                <div className="row">
                    <div class="col-lg-12">

                        <h4 style={{ fontSize: "50px" }}>Password Reset Code Sent </h4>
                        <div class="title-bdr"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="default-col ticket-col checkout">
                        <p style={{ color: '#a3520f', fontSize: '20px' }}>
                            check your email for the reset code<br />
                            Proceed to complete password reset

                        </p>

                        <div class="col-md-12">
                            <button class="btn btn-primary theme-btn addrbtn" type="submit" onClick={() => window.location.href = '/resetpwd'}>Proceed to create new password <i class="arrow_right"></i></button>
                        </div>
                    </div>
                </div>
            </div>





        </div>
    );
}

export default PwdResetRequestSuccess;