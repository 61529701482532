


export class AuthorizeService {

    //check if user is authenticated
    async isAuthenticated() {

        if (JSON.parse(localStorage.getItem('uAuth')) === true) { return true; }

        return false;
    }

    async getAccessToken() {
        return localStorage.getItem('jwttoken');

    }

    async getUser() {
        return localStorage.getItem('username');
    }

    async signIn(username,token) {
        //set local storage

         localStorage.setItem('username', username);
         localStorage.setItem('jwttoken', token);
         localStorage.setItem('uAuth', true);
    }

    async signOut() {
        //clear local storage
        localStorage.removeItem('username');
        localStorage.removeItem('jwttoken');
        localStorage.removeItem('uAuth');
        //localStorage.setItem('uAuth', false);
        localStorage.clear();
        window.location.href = "/login";
    }

}

const authService = new AuthorizeService();

export default authService;