
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import userService from '../components/Auth/UserService';
import ResetSuccess from "./ResetSuccess";

export { ResetPassword };
function ResetPassword() {
    const [_status, setPassword] = useState(false);
    const token = userService.getAccessToken();
    const [user, setUser] = useState();
    const navigate = useNavigate();

   const handleClick = () => {

        setPassword(!_status);

    };
    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required'),
        resetcode: Yup.string()
            .required('Reset code is required'),
        password: Yup.string()
            .required('New Password is required')
            .min(6, 'Password must be at least 6 characters')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    

    //reset form
    useEffect(() => {
        console.log(user);
        reset(user);

    }, [user]);

    const onSubmit = ({ email, resetcode, password }) => {
        /* e.preventDefault();*/


        let addrinfo = {
            email: email,
            resetcode: resetcode,
            password: password
        };

        console.log(addrinfo);
        fetch("api/users/restp", {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(addrinfo)
        }).then((rsp) => {
            if (rsp.status === 201 || rsp.status === 200) {
               // console.log('password reset successfully.')
               // navigate('/resetsuccess');
               //setPassword(_status = !_status);
                handleClick();
            }
            else {
                alert('Sorry your password could not be reset');
            }

        }).catch((err) => {
            toast.error('Failed :' + err.message);
            console.log('password reset unsuccessfully.');
        });

    }
    return (
        
        
        <div className="container">
            {/* <h4 className="">Contact Information</h4>*/}
            {/*<div>*/}
            {/*    {!_status && <h1>Password not reset</h1>}*/}

            {/*    {_status && <h1>Password reset successful</h1>}*/}

            {/*    <button onClick={handleClick}> reset</button>*/}

            {/*</div>*/}
            {!_status &&
            <div className="default-col ticket-col checkout">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="title-col title-white-col">

                            <h3>Reset Your Password</h3>
                                <p style={{ color: '#a3520f' }}>Please  check your email for the reset code:</p>
                            <div class="title-bdr"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-11">
                    <div className="row">
                      
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="row">
                                    <div className="col-md-7">
                                        <label className="form-label">Login(Email)</label>
                                        <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <label className="form-label">Reset Code</label>
                                        <input name="resetcode" type="text" {...register('resetcode')} className={`form-control ${errors.resetcode ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.resetcode?.message}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <label className="form-label">New Password</label>
                                        <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.password?.message}</div>
                                    </div>
                                </div>

                                <button disabled={isSubmitting} className="btn btn-primary theme-btn addrbtn" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                    Submit
                                </button>
                                {/*<Link to="/myprofile" on className="btn btn-link">Cancel</Link>*/}
                            </form>
                        
                   
                </div>

                </div>
               
                </div>
            }
            {_status && <ResetSuccess />}

        </div>
    )


}