
import React, { Component } from 'react';
import authService from "./Auth/AuthService";


function FormatDate(dstring) {
    // Create a date object from a date string
    //var date = new Date("Wed, 04 May 2022");
    var date = new Date(dstring);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    console.log(formattedDate);  // Prints: 2022-05-04
    return formattedDate;
}
export class Arrival extends Component {
    static displayName = Arrival.name;
    constructor(props) {
        super(props);
        this.state = {
            firstname:null,
            isAuthenticated: false,
            userName: null,
            email: null,
            arrivaldate: '2024-08-29',
            departuredate: '2024-09-02'
        }
       
    }
    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        //just make sure username is set here before you call any db functions
        //this.populateRegistrationData();
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const token = await authService.getAccessToken();
        this.setState({
            isAuthenticated,
            userName: user,
            email: user
        });

        //fetch my registration profile info
        fetch('api/registration/user/' + user, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            //.then(rsp => { if (rsp.status === 201 || rsp.status === 200) { rsp.json() } })
            .then(rsp => rsp.json())
            .then(data => {
                //if (data != null) {
                this.setState( prevState=>({
                    firstname:prevState.firstname = data[0].firstName,
                    middlename: data[0].middleName,
                    lastname: data[0].lastName,
                    
                    arrivaldate: prevState.arrivaldate = data[0].arrivalDate == null ? '2024-08-29': FormatDate(new Date(data[0].arrivalDate)),
                    departuredate: prevState.departuredate = data[0].arrivalDate == null ? '2024-09-02' : FormatDate( new Date(data[0].departureDate))
                }));
              
            });
        
    }

    //fires on Input Box Value Change
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //
    onSubmit = (e) => {

        e.preventDefault();
        
        let info = {
            email: this.state.email,
            arrivaldate: this.state.arrivaldate,
            departuredate: this.state.departuredate,
           
        };

         var url = '/api/registration/updatearrival';

        fetch(url, {
            body: JSON.stringify(info),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            credentials: 'same-origin',
            method: 'POST'
        })
            .then(rsp => {
                if (rsp.status === 201 || rsp.status === 200) {
                    console.log('Info Added Successfully ' + this.state.scans + 'Please Check Email');
                    // alert('Info Added Successfully ' + this.state.scans + 'Please Check Email');
                    window.location.href = "/myprofile"
                }
            })
            .catch(err => {
                console.error(err);
                alert(err);
            });

        this.setState({ scans: [], showform: false });

    }

    render() {

        

        return (
            <div>
                {/*  <!-- Ticket Start -->*/}

                <div className="container">
                    <div className="row">
                        <div class="col-lg-12">
                            <div class="title-col title-white-col">
                                <h6>Ceana 2024 Convention Registration: {this.state.firstname}, {this.state.lastname} {/*&nbsp;<a href='/dependants' > My Attendees</a>*/}</h6>
                                <h2>Hello {this.state.firstname }!!  Welcome Back</h2>
                                <div class="title-bdr"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="default-col ticket-col checkout">
                                <p style={{ color: '#a3520f' }}>Let us know when you plan on arriving and departing </p>
                                <form>
                                    <div class="row">
                                        
                                        <div class="col-md-6">
                                            <label> Arrival Date</label>
                                            <input type="text" class="form-control" placeholder="Arrival Date yyyy/mm/dd" name="arrivaldate" type="date" value={this.state.arrivaldate} onChange={this.handleChange} />
                                        </div>
                                        <div class="col-md-6">
                                            <label> Departure Date</label>
                                            <input type="text" class="form-control" placeholder="Departure Date yyyy/mm/dd" name="departuredate" type="date" value={this.state.departuredate} onChange={this.handleChange} />
                                        </div>

                                    </div>
                                </form>
                                <div class="col-md-12">
                                    <button class="btn btn-default theme-btn addrbtn" type="submit" onClick={this.onSubmit}>Save Changes <i class="arrow_right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        );
    }
}