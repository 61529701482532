import { Routes, Route, Navigate } from 'react-router-dom';

import { MyProfile } from './MyProfile';

import userService from '../components/Auth/AuthService';
import { AssocEdit } from './AssocEdit';

export { ProfileLayout }

function ProfileLayout() {
    const auth = JSON.parse(localStorage.getItem('uAuth')) === true ? true : false //userService.isAuthenticated();
    const regstatus = JSON.parse(localStorage.getItem('uReg')) === true ? true : false //userService.isAuthenticated();
    /*const auth =userService.isAuthenticated();*/


    if (!auth) {
        return <Navigate to="/login" />;
    }
    else if (regstatus) {
        return <Navigate to="/RegistrationCompleteDetails" />;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
                    <Routes>
                        <Route index element={<MyProfile />} />
                        <Route path="/assocedit" element={<AssocEdit/> }/>
                      
                    </Routes>
                </div>
            </div>
        </div>
    );
}