import { Link,useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


import authService from '../components/Auth/AuthService';
//import { useDispatch } from 'react-redux';

//import { authActions } from '_store';

export { Login };

function Login() {
    const navigate = useNavigate();
    //const dispatch = useDispatch();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required').email('Username is invalid'), 
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
       // return dispatch(authActions.login({ username, password }));
        let inputobj = {
            "username": username,
            "password": password
        };
        fetch("/api/Users/Authenticate", {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(inputobj)
        }).then((res) => {
            if (res.status === 201 || res.status === 200) {
                return res.json();
            }
        }).then((resp) => {
            //console.log(resp)

            //if invalid do not procee
            // if (Object.keys(resp).length === 0) 
            if (Object.keys(resp).length === 0) {
               // toast.error('Login failed, invalid credentials');
            } else {
                //toast.success('Success');
                // localStorage.setItem('username', username);
                //localStorage.setItem('jwttoken', resp.jwtToken);
                // localStorage.setItem('uAuth', true);
                //console.log(resp.token)
                authService.signIn(username.toLowerCase(), resp.token);

                navigate('/postlogin');
               /* navigate('/myprofile');*/
            }
            
        }).catch((err) => {
            //toast.error('Login Failed due to :' + err.message);
        });
    }

    return (
        <div className="card m-3">
            <h4 className="card-header">Login</h4>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                    <button disabled={isSubmitting} className="btn btn-primary">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        Login
                    </button>
                    <Link to="../newuser" className="btn btn-link">Create Account</Link>
                </form>
                <div style={{marginTop:"20px"} }>
                    <Link to="../reqpwd" className="btn btn-link">Forgot Password?</Link>
                </div>
                
            </div>
        </div>
    )
}
