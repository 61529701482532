
import { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

import userService from "../components/Auth/UserService";
import { Register_Step1 } from '../components/Register_Step1';


//import { UserService } from '../components/Auth/UserService';



export { List };

function List() {
    //const token = userService.getAccessToken();
    //const uname = userService.getUser();
    const token = localStorage.getItem('jwttoken');
    const uname = localStorage.getItem('username');
    //const isAuthenticated = localStorage.getItem('uAuth');

    const [attendees, setAttendees] = useState([]);
    //const users = userService.getAllAttendees();

    const navigate = useNavigate();
    //const login = () => navigate('/login');//authService.login();
    const refreshPage = () => navigate('/myattendees');//authService.login();

    //console.log("here are the list of users\n\n" + users);

    useEffect(() => {
        //userService.getAll();
        //userService.getAllAttendees();

        //fetch my registration data
        fetch('api/packageregistration/user/' + uname, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAttendees(data);
            });
    }, []);
    console.log("here are the list of users\n\n" + JSON.stringify(attendees));

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div class="">
                    <div class="default-col ticket-col checkout">
                            <div className="row">
                                <div class="col-lg-11">
                                    <div class="title-col title-white-col">
                                        <h6>Ceana 2024 Convention Registration: {/*&nbsp;<a href='/dependants' > My Attendees</a>*/}</h6>
                                        <h3>My Entourage</h3>
                                        <p style={{ color: '#a3520f' }}>Let us know who is accompanying you to the convention, please list them here: </p>
                                        <div class="title-bdr"></div>
                                    </div>
                                </div>
                            </div>
                            {/*<Link to="add" className="btn btn-sm btn-success mb-2">Add Attendee</Link>*/}
                            <div className="row">
                                <div class="">
                                <table className="table table-striped attendees " >
                                   <thead>
                                      <tr>
                                        <th style={{ width: '24%' }}></th>
                                        <th style={{ width: '24%' }}></th>
                                        <th style={{ width: '42%' }}></th>
                                        <th style={{ width: '10%' }}></th>
                                      </tr>
                                   </thead>
                                  <tbody>
                                       <tr>
                                          <td><b>First Name</b></td> <td><b>Last Name</b></td> <td><b>Convention Package</b></td>
                                            <td><Link to="add" className="btn btn-sm btn-success mb-2">Add Attendee</Link></td>
                                        </tr>
                                        {attendees?.map(user =>
                                                           <tr key={user.recId}>
                                                           <td>{user.firstName}</td>
                                                           <td>{user.lastName}</td>
                                                           <td>{user.packageDescription == null ? 'Click Modify To Select Your Convention Registration Package' : user.packageDescription + ' $' + user.amount}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>
                               
                                         <Link to={`edit/${user.recId}`} className="btn btn-sm btn-primary me-1">Modify</Link>
                                            {!user.isPrimary &&
                                                        <button onClick={() => { userService.delete(user.recId); window.location.href='/myattendees';/*refreshPage();*/ }} className="btn btn-sm btn-danger" style={{ width: '60px' }} disabled={user.isDeleting}>
                                                           {user.isDeleting
                                                                          ? <span className="spinner-border spinner-border-sm"></span>
                                                                          : <span>Remove</span>
                                                                 }
                                              </button>
                                                                    }
                                                      </td>
                                                 </tr>
                                                        )}
                                        {attendees?.loading &&
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    <span className="spinner-border spinner-border-lg align-center"></span>
                                                </td>
                                            </tr>
                                        }
                                                                                              
                                    </tbody>
                                    </table>
                                 </div>
                            </div>
                            
                            <div className="row">
                                <div className="">
                                    <div>
                                        <button type="button" class="button addrbtn reviewbtn" onClick={() => window.location.href = "/myprofile"}>Go Back</button>
                                         <button type="button" class="button" onClick={()=>navigate('/review')}>Proceed To Checkout!!</button>
                                </div>

                                </div>
                              
                           </div>
                        </div>
                    </div>

                </div>
                


            </div>
           
        </div>
    );
}