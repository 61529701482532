import { Routes, Route, Navigate } from 'react-router-dom';

import { List, AddEdit } from './';

export { AttendeesLayout };

function AttendeesLayout() {

    const auth = JSON.parse(localStorage.getItem('uAuth')) === true ? true : false //userService.isAuthenticated();
    /*const auth =userService.isAuthenticated();*/


    if (auth === false || auth === null) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    return (
        <div className="p-4">
            <div className="container">
                <Routes>
                    <Route index element={<List />} />
                    <Route path="add" element={<AddEdit />} />
                    <Route path="edit/:recId" element={<AddEdit />} />
                </Routes>
            </div>
        </div>
    );
}
