import { Routes, Route, Navigate } from 'react-router-dom';
/*import { useSelector } from 'react-redux';*/

import { Login, CreateUser, ResetPassword } from './';
import userService from '../components/Auth/UserService';
import Welcome from './Welcome';
import ResetSuccess from './ResetSuccess';
import { RequestPassword } from './RequestPassword';
//import SendMail from './SendMail';

export { AccountLayout };

function AccountLayout() {
  /*  const auth = userService.isAuthenticated();*/
    const auth = JSON.parse(localStorage.getItem('uAuth')) === true ? true : false //userService.isAuthenticated();

    // redirect to home if already logged in
    if (auth) {
        return <Navigate to="/myprofile" />;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
                    <Routes>
                        <Route path="login" element={<Login />} />
                        <Route path="newuser" element={<CreateUser />} />
                        <Route path="welcome" element={<Welcome />} />
                        <Route path="reqpwd" element={<RequestPassword />}/>
                        <Route path="resetpwd" element={<ResetPassword />} />
                        <Route path="resetsuccess" element={<ResetSuccess />} />
                       
                    </Routes>
                </div>
            </div>
        </div>
    );
}
