import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import userService from '../components/Auth/UserService';

function Payment(props) {
    const { stripePromise } = props;
    const token = userService.getAccessToken();
    const [clientSecret, setClientSecret] = useState('');

    //useEffect(() => {
    //    // Create PaymentIntent as soon as the page loads
    //    fetch("api/secret")
    //        .then((res) => res.json())
    //        .then(({ clientSecret }) => setClientSecret(clientSecret));
    //}, []);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        let email = localStorage.getItem('username')
        fetch("api/secret", {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'content-type': 'application/json' },
            body:JSON.stringify(email)

          }).then((res) => res.json())
            .then(({ clientSecret }) => setClientSecret(clientSecret));
    }, []);

    return (
        <div className="container">
            <div className="default-col ticket-col checkout" >
                <h1>Payment</h1>
                {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{
                        clientSecret,
                        theme: 'night',
                        layout: {
                            type: 'tabs',
                            defaultCollapsed: false,
                        },
                        variables: {
                            colorPrimary: '#0570de',
                            colorBackground: '#ffffff',
                            colorText: '#30313d',
                            colorDanger: '#df1b41',
                            fontFamily: 'Ideal Sans, system-ui, sans-serif',
                            spacingUnit: '2px',
                            borderRadius: '4px',
                            // See all possible variables below
                        },
                        rules:{
                            '.Tab': {
                                border: '1px solid #E0E6EB',
                                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                            }
                        }

                    }}>
                        <CheckoutForm />
                    </Elements>
                )}

            </div>
           
        </div>
    );
}

export default Payment;