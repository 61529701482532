import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';


import authService from "../components/Auth/AuthService";
import { Arrival } from '../components/Arrival';
import { Address } from './Address';

export { MyProfile }
function MyProfile(){
    const init = JSON.parse(localStorage.getItem('uAuth')); //JSON.parse(localStorage.getItem('uAuth')) === false
    
    const navigate = useNavigate();

    //const login = () => navigate('/login');//authService.login();
    const refreshPage = () => navigate('/login');//authService.login();
    useEffect(() => {
        //clear local storage on initial pageload
        if (init === false) {
            localStorage.clear();
        }
    }, []);
   
   
        
        return (
            <div>
                <div className="address" >
                    <Arrival />
                </div>
                {/*<div class="row">*/}
                {/*    <div class="col-lg-12">*/}
                {/*        <div class="title-col title-white-col">*/}
                {/*            <h6>Ceana 2024  &nbsp;<a href='/dependants' > My Attendees</a></h6>*/}
                {/*            <h2>Hello {this.state.firstname + ' ' + this.state.lastname}!!  Welcome Back</h2>*/}
                {/*            <div class="title-bdr"></div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="address" >
                    <Address/>
                </div>
                
                
                {/*<div>*/}
                {/*    <button type="button" class="button" onClick={() => window.location.href = "/dependants"}>Continue!!</button>*/}
                {/*</div>*/}
            </div>
            

        );
  


}