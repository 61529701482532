
import { useEffect, useState } from 'react';

function Welcome(props) {
    
    
    useEffect(() => {
       
    }, []);

    return (
        <div>
            {/*  <!-- Ticket Start -->*/}

            <div className="container">
                <div className="row">
                    <div class="col-lg-12">
                        
                        <h1 style={{fontSize:"50px"} }>Welcome  !! </h1>
                            <div class="title-bdr"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="default-col ticket-col checkout">
                            <p style={{ color: '#a3520f', fontSize:'20px' }}>
                                You have Successfully Created A CEANA Events User Account.<br/>
                                Please proceed to Login to complete  your 2024 Convention Registration

                            </p>
                            
                            <div class="col-md-12">
                            <button class="btn btn-primary theme-btn addrbtn" type="submit" onClick={()=>window.location.href= '/login' }>Proceed to Login <i class="arrow_right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
          




        </div>
    );
}

export default Welcome;