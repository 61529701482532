

const attendeesKey = 'attendees'
const token = localStorage.getItem('jwttoken');
let attendees = JSON.parse(localStorage.getItem(attendeesKey)) || [];
export class UserService{
    //get all dependants
    async getAll() {

    }

    async addAttendee(data) {
        const attendee =data;

    //if (users.find(x => x.username === user.username)) {
    //    return error('Username "' + user.username + '" is already taken')
    //}

        attendee.id = attendees.length ? Math.max(...attendees.map(x => x.id)) + 1 : 1;
        attendees.push(attendee);
        localStorage.setItem(attendeesKey, JSON.stringify(attendees));
        //return ok();
    }

     async basicDetails(user) {
        const { id, firstName, lastName } = user;
        return { id,  firstName, lastName };
    }

    async getAllAttendees() {
        return attendees.map(x=>this.basicDetails(x));
    }

    //get Attendee info to edit
    async getAttendeeByRecId(recid) {
        let info = {
            email: localStorage.getItem('username'),
            recId:recid
        }
        let res=[];
        //fetch my registration data
        fetch('api/getattendee', {
            body: JSON.stringify(info),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            credentials: 'same-origin',
            method: 'POST'
        })
            .then(response => response.json())
            .then(data => {
               res = data[0];
            });
        return res;
    }
    //delete or remove attendee
    async delete(recId) {
        let info = {
            email: localStorage.getItem('username'),
            recId: recId
        }
        //let res = [];
        //fetch my registration data
        fetch('api/removeAttendee', {
            body: JSON.stringify(info),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            credentials: 'same-origin',
            method: 'POST'
        })
            .then(rsp => {
                if (rsp.status === 201 || rsp.status === 200) {
                    console.log('conference attendee removed successfully');
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    async isAuthenticated() {

        if (JSON.parse(localStorage.getItem('uAuth')) === true) { return true; }

        return false;
    }

    async getAccessToken() {
        return localStorage.getItem('jwttoken');

    }

    async getUser() {
        return localStorage.getItem('username');
    }

    async signIn(username, token) {
        //set local storage

        localStorage.setItem('username', username);
        localStorage.setItem('jwttoken', token);
        localStorage.setItem('uAuth', true);
    }

    async signOut() {
        //clear local storage
        localStorage.removeItem('username');
        localStorage.removeItem('jwttoken');
        localStorage.removeItem('uAuth');
        localStorage.removeItem('uReg');
    }


}
const userService = new UserService();

export default userService;