
import { Navigate, Outlet } from 'react-router-dom';
import { Counter } from "./components/Counter";
/*import { CreateUser } from "./components/CreateUser";*/
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
//import { Login } from "./account/Login";
//import { CreateUser } from './account/CreateUser';
//import { MyProfile } from "./components/MyProfile";
/*import { Register } from "./components/Register";*/
import { Register_Step1 } from "./components/Register_Step1";
import { SelectPackage_Step2 } from "./components/SelectPackage_Step2";
import { AddDependantForm } from "./components/AddDependant_Step3";
import { ReviewRegistration_Step4 } from "./components/ReviewRegistration_Step4";
import { AddDependant_Step3 } from "./components/AddDependant_Step3";
import { Login3 } from "./components/login3";


import CheckoutForm from "./components/CardDemo";
//import { Component } from "react";
import { Review } from "./components/Review";
/*import { MyProfile } from "./profile/MyProfile";*/
import Completion from "./components/Completion";
import SendMail from './account/SendMail';

export { PrivateRoutes };

const AppRoutes = [
    { index: true, element: <Home /> },
    { path: '/counter', element: <Counter /> },

    { path: '/fetch-data', element: <FetchData /> },
    //{ path: '/login', element: <Login /> },
    //{ path: '/newuser', element: <CreateUser /> },
    { path: '/login3', element: <Login3 /> },
    /*{ path: '/myprofile', element: <MyProfile /> },*/
    /* { path: '/myprofile', element: <MyProfile /> },*/

    //{ path: '/review', element: <Review /> },

    /* { path: '/register', element: <Register /> },*/
    { path: '/register/step1', element: <Register_Step1 /> },

    { path: '/register/step2', element: <SelectPackage_Step2 /> },
    { path: '/register/step3', element: < AddDependant_Step3 /> },
    { path: '/register/step4', element: <ReviewRegistration_Step4 /> },
    { path: '/register/step5', element: <SelectPackage_Step2 /> },
    { path: '/stripe', element: <CheckoutForm /> },
    /* {path: '/completion', element:<Completion/> },*/
    { path: '/sendmail', element:<SendMail />}
  
];

export default AppRoutes;

function PrivateRoutes() {

    const auth = JSON.parse(localStorage.getItem('uAuth'));

    if (!auth) {
        return <Navigate to="/login"/>
    }

    return <Outlet />;

}
