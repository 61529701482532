import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import userService from '../components/Auth/UserService';
import authService from "../components/Auth/AuthService";



export { AddEdit };

function AddEdit() {
    const token = userService.getAccessToken();
    const { recId } = useParams();
    const [title, setTitle] = useState();
    const [packages, setPackageOptions] = useState([]);
    const [user, setUser] = useState();
    //const dispatch = useDispatch();
    //const user = userService.getAttendeeByRecId(recId);//[];//useSelector(x => x.users?.item);
   
    const navigate = useNavigate();
    //const login = () => navigate('/login');//authService.login();
    const refreshPage = () => navigate('/myattendees');//authService.login();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        package: Yup.string()
            .required('please select a package')
        //username: Yup.string()
        //    .required('Username is required'),
        //password: Yup.string()
        //    .transform(x => x === '' ? undefined : x)
        //    // password optional in edit mode
        //    .concat(id ? null : Yup.string().required('Password is required'))
        //    .min(6, 'Password must be at least 6 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    //load user data into form for editing
    useEffect(() => {
        setUser(userService.getAttendeeByRecId(recId));
        let info = {
            email: localStorage.getItem('username'),
            recId: recId
        }
        //let res = [];
        //fetch my registration data
        fetch('api/getattendee', {
            body: JSON.stringify(info),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            credentials: 'same-origin',
            method: 'POST'
        })
            .then(response => response.json())
            .then(data => {
                setUser(data[0]);
            });
        // effect runs on component mount
       
    },[]);
    //useEffect(() => {
    //    // simulate async api call with set timeout
    //    setTimeout(() => setUser({ firstName: 'Frank', lastName: 'Murphy' }), 1000);
    //}, []);

    useEffect(() => {
        if (recId) {
            setTitle('Edit Attendee');
            // fetch user details into redux state and
            // populate form fields with reset()
            //dispatch(userActions.getById(id)).unwrap()
              //  .then(user => reset(user));
            reset(user);
            //console.log(recId);
            console.log(user);
            //console.log(JSON.stringify(userService.getAttendeeByRecId(recId)[0]));
        } else {
            setTitle('Add Attendee');
        }
    }, [user]);

    //Load Conference Packages
    useEffect(() => {
        //userService.getAll();
        //userService.getAllAttendees();

        //fetch my registration data
        fetch('api/packages/all', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setPackageOptions(data);
            });
    }, []);
    async function onSubmit(data) {
        //dispatch(alertActions.clear());
        try {
            // create or update user based on id param
            let message;
            if (recId) {
                //await dispatch(userActions.update({ id, data })).unwrap();
                let info = {
                    recId:recId,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    packageId: data.package,
                    packageDescription: packages.filter(x => x.packageId == data.package)[0].description,
                    amount: packages.filter(x => x.packageId == data.package)[0].amount,
                    email: localStorage.getItem('username')//authService.getUser()
                }

                //Begin
                var url = '/api/UpdateAttendee';
                fetch(url, {
                    body: JSON.stringify(info),
                    cache: 'no-cache',
                    headers: {
                        'content-type': 'application/json'
                    },
                    credentials: 'same-origin',
                    method: 'PUT'
                })
                    .then(rsp => {
                        if (rsp.status === 201 || rsp.status === 200) {
                            console.log('conference attendee updated successfully');
                            refreshPage();
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
                //End

                console.log("new data is : " + JSON.stringify(data));
                message = 'User updated';
            } else {
               // await dispatch(userActions.register(data)).unwrap();
                //await userService.addAttendee(data);
                //console.log(data);

                let info = {
                    firstName : data.firstName,
                    lastName : data.lastName,
                    packageId: data.package,
                    description: packages.filter(x => x.packageId == data.package)[0].description,
                    amount: packages.filter(x => x.packageId == data.package)[0].amount,
                    email: localStorage.getItem('username')//authService.getUser()
                }
                
                //Begin
                var url = '/api/AddAttendee';
                fetch(url, {
                    body: JSON.stringify(info),
                    cache: 'no-cache',
                    headers: {
                        'content-type': 'application/json'
                    },
                    credentials: 'same-origin',
                    method: 'POST'
                })
                    .then(rsp => {
                        if (rsp.status === 201 || rsp.status === 200) {
                            console.log('conference attendee added successfully');
                            refreshPage();
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
                //End
                message = 'User added';
                console.log(info);
            }

            // redirect to user list with success message
            //usenavigate.navigate("/dependants");
           // dispatch(alertActions.success({ message, showAfterRedirect: true }));
        } catch (error) {
            //dispatch(alertActions.error(error));
        }
        //refreshPage();
    }


    //package options data
    let packageoptiondata = packages;
    let optionItems = packageoptiondata.map(item =>
        <option key={item.packageId} value={parseInt(item.packageId)} name={item.description}>{item.description}{' $' + item.amount}</option>
    );


    return (
        <>
            <h1>{title}</h1>
            {!(user?.loading || user?.error) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">First Name</label>
                            <input name="firstName" type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.firstName?.message}</div>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Last Name</label>
                            <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.lastName?.message}</div>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Package</label>
                            <select name="title" {...register('package')} className="form-select">
                                { optionItems}
                            </select>
                            <div className="invalid-feedback">{errors.package?.message}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{ marginTop:"15px" }}>
                           <button type="submit" disabled={isSubmitting} className="btn btn-primary me-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Save
                         </button>
                        <button onClick={() => reset()} type="button" disabled={isSubmitting} className="btn btn-secondary">Reset</button>
                        <Link to="/myattendees" className="btn btn-link">Cancel</Link>
                        </div>
                    </div>

                </form>
            }
            {user?.loading &&
                <div className="text-center m-5">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            {user?.error &&
                <div class="text-center m-5">
                    <div class="text-danger">Error loading user: {user.error}</div>
                </div>
            }
        </>
    );
}