/*import './App.css';*/
import React from 'react';
import Payment from './/components/Payment';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes, { PrivateRoutes } from './AppRoutes';
import { Layout } from './components/Layout';
/*import '../src/css/responsive.css';*/
import './custom.css';

import { loadStripe } from '@stripe/stripe-js';
import { AttendeesLayout } from './Attendees/AttendeesLayout';
import { AccountLayout } from './account/AccountLayout';
import { ProfileLayout } from './profile/ProfileLayout';
import Completion from './components/Completion';
import { Review } from './components/Review';
import { RegistrationCompleteDetails } from './components/RegistrationCompleteDetails';
import PostLogin from './account/PostLogin';
/*import { CreateUser } from './account/CreateUser';*/
/*import { AccountLayout } from './AccountLayout';*/

function App () {
  //static displayName = App.name;
    const [stripePromise, setStripePromise] = useState(null);

    //Publishable Key
    useEffect(() => {
        fetch("api/config").then(async (r) => {
            const { publishableKey } = await r.json();
            //console.log(publishableKey);
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    //Payment Intent


  
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}   <Route path="/*" element={<AccountLayout />} />
                {/*<Route path="/newuser" element={<CreateUser/>} />*/}


                {/* Private Routes*/}
                <Route element={<PrivateRoutes />}>
                    <Route path="/postlogin" element={<PostLogin /> }/>
                    <Route path="/myprofile/*" element={<ProfileLayout />} />
                    <Route path="/myattendees/*" element={<AttendeesLayout />} />
                    <Route path="/review" element={<Review/> } />

                    <Route path="/pay" element={<Payment stripePromise={stripePromise} />} />
                    <Route path="/completion" element={<Completion />} />
                    <Route path="/RegistrationCompleteDetails" element={<RegistrationCompleteDetails />} />

                </Route>
                {/* End Private Routes*/}
               
                
        </Routes>
      </Layout>
    );
 
}
export default App;