import { useEffect, useState } from 'react';

function Completion(props) {
    const [messageBody, setMessageBody] = useState('');
    const { stripePromise } = props;

    useEffect(() => {
        if (!stripePromise) return;

        stripePromise.then(async (stripe) => {
            const url = new URL(window.location);
            const clientSecret = url.searchParams.get('payment_intent_client_secret');
            const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
           

            setMessageBody(error ? `> ${error.message}` : (
                <>&gt; Payment {paymentIntent.status}: <a href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
            ));
        });
    }, [stripePromise]);

    useEffect(() => {
        //check registration status, if true do nothing else update registration status and send confirmation email
        SendMail();
    },[]);

    //check registration status, if true do nothing else update registration status and send confirmation email
    function SendMail(event){
        /* event.preventDefault();*/

        let email = localStorage.getItem("username");
        let addrinfo = {
            email: "info@ndigo.io"

        };

        console.log(email);
        fetch("api/registration/complete", {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(email)
        }).then((rsp) => {
            if (rsp.status === 201 || rsp.status === 200) {
                // console.log('password reset successfully.')
                // navigate('/resetsuccess');
                //setPassword(_status = !_status);
                //handleClick();
                console.log("Registration Confirmation Email Sent")
                localStorage.setItem('uReg', true);
            }
            else {
                alert('Sorry email could not be sent');
                console.log("Could not send email");
            }

        }).catch((err) => {
            //toast.error('Failed :' + err.message);
            console.log('unknown error occurred.');
        });

    }





    return (
        <div>
           
           
      

         <div className="container">
                <div className="row">
                    <div class="col-lg-12">
                        
                        <h1 style={{fontSize:"50px"} }>Congratulations  !! </h1>
                            <div class="title-bdr"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="default-col ticket-col checkout">
                            <p style={{ color: '#a3520f', fontSize:'20px' }}>
                            Your CEANA 2024 Convention Registration was Successful<br />
                            You should receive an email confirmation shortly. If you do not see a confirmation email, please check your junk mail,
                            You may also contact us at admin@ceanaevents.org.<br/>
                            See you in Colorado

                        </p>

                        <div id="messages" role="alert" style={messageBody ? { display: 'block' } : {}}>{messageBody}</div>

                            <div class="col-md-12">
                            <button class="btn btn-primary theme-btn addrbtn" type="submit" onClick={() => window.location.href = '/'} >Learn More About Colorado <i class="arrow_right"></i></button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default Completion;