import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from "react-toastify";


export { CreateUser };
 function CreateUser(){
     
    const navigate = useNavigate();

     // form validation rules 
     const validationSchema = Yup.object().shape({
         firstName: Yup.string()
             .required('First Name is required'),
         lastName: Yup.string()
             .required('Last Name is required'),
         email: Yup.string()
             .required('Email is required').email('Email is invalid'),
         password: Yup.string()
             .required('Password is required')
             .min(6, 'Password must be at least 6 characters')
     });
     const formOptions = { resolver: yupResolver(validationSchema) };

     // get functions to build form with useForm() hook
     const { register, handleSubmit, formState } = useForm(formOptions);
     const { errors, isSubmitting } = formState;


     function onSubmit ({ firstName, lastName, email, password }) {
       /* e.preventDefault();*/


         let signupinfo = {
             firstName: firstName,
             lastName: lastName,
             email:email,
             password:password
         };
       
            console.log(signupinfo);
            fetch("api/users/newuser", {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(signupinfo)
            }).then((rsp) => {
                if (rsp.status === 201 || rsp.status === 200) {
                    toast.success('Registered successfully.')
                    navigate('/welcome');
                }
                else {
                    alert('Sorry Account Could not be Created, try again');
                }
                
            }).catch((err) => {
                toast.error('Failed :' + err.message);
            });
       
    }
     return (
         <div className="card m-3">
             <h4 className="card-header">Create Account</h4>
             <div className="card-body">
                 <form onSubmit={handleSubmit(onSubmit)}>
                     <div className="mb-3">
                         <label className="form-label">First Name</label>
                         <input name="firstName" type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                         <div className="invalid-feedback">{errors.firstName?.message}</div>
                     </div>
                     <div className="mb-3">
                         <label className="form-label">Last Name</label>
                         <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                         <div className="invalid-feedback">{errors.lastName?.message}</div>
                     </div>
                     <div className="mb-3">
                         <label className="form-label">Email</label>
                         <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                         <div className="invalid-feedback">{errors.email?.message}</div>
                     </div>
                     <div className="mb-3">
                         <label className="form-label">Password</label>
                         <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                         <div className="invalid-feedback">{errors.password?.message}</div>
                     </div>
                     <button disabled={isSubmitting} className="btn btn-primary">
                         {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                         Create
                     </button>
                     <Link to="../login" className="btn btn-link">Cancel</Link>
                 </form>
             </div>
         </div>
     )
}
