import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ceanabanner from '../images/ceana-2024-caled.jpeg';
export { Home }
function Home() {
    const init = JSON.parse(localStorage.getItem('uAuth')) //JSON.parse(localStorage.getItem('uAuth')) === false
  //static displayName = Home.name;
    const navigate = useNavigate();

    //const login = () => navigate('/login');//authService.login();
    const refreshPage = () => navigate('/login');//authService.login();
    useEffect(() => {
        //clear local storage on initial pageload
        if (init === false) {
            localStorage.clear();
        }
    },[]);
 
    return (

       
        <div >
            <div >
                <section class="default-section banner-area bannre-two-area bannre-three-area " >
                    <div class="banner-content-table">
                        <div class="banner-content-middle">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-8">
                                    <div class="banner-col"><p>Welcome To</p>
                                        <h1 class="gradient-text">CEANA Events One Platform for All  </h1>
                                        <div>
                                            <button type="button" class="button" onClick={() => navigate('/newuser')}>Proceed to CEANA 2024 Registration</button>
                                        </div>
                                       
                                        </div>
                                        
                                  </div>
                                </div>
                                <div class="row" >
                                    <img class="ceanabanner" src={ceanabanner} style={{ marginTop: "40px", marginBottom:"10px" }} ></img>
                                </div>

                            </div></div></div>
                    <div class="my-parallax-img"><img src="/images/hotel/marriot-airport.jpg" alt="" />
                    </div>

                </section>
                <section>
                    
                   
                   
                </section>
            </div>
            
           
            
       </div>
    );
 
}
